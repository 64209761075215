/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        UTIL.goedzetten();

        //$('.gallery').magnificPopup({delegate: '.image-link',type:'image',gallery:{ enabled:true} });

      $('a[href$=".jpg"], a[href$=".png"]').fullsizable({fullscreenButton: false});


        $(document).on('fullsizable:opened', function(){
          $("#jquery-fullsizable").swipe({
            swipeLeft: function(){
              $(document).trigger('fullsizable:next');
            },
            swipeRight: function(){
              $(document).trigger('fullsizable:prev');
            },
            swipeUp: function(){
              $(document).trigger('fullsizable:close');
            }
          });
        });


        $.each( $('.parallax div'), function() {
            //$(this).parallax({imageSrc: $(this).attr('data-image-src')) });
         // console.log( $(this).attr('data-image-src') );
          var img = $(this).attr('data-image-src');
          var pspeed = $(this).attr('data-speed');
          var ix = $(this).attr('data-z-index');
          $(this).parallax( {imageSrc: img, speed: pspeed, zIndex: ix, bleed: 0 } ).attr('style','').find('img').animate({ opacity: '0'},100);
        } );

        $(window).trigger('resize').trigger('scroll');

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {


      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    },
    goedzetten: function(){

      var responsive_viewport = $(window).width();
        if (responsive_viewport > 765) {

          var introteksthoogte = 0;
          var sidebarhoogte = 0;
          var padding = 0;
          var initheight = 0;

          initheight = $(".inner-side").outerHeight();
          introteksthoogte = $(".intro").outerHeight();
          sidebarhoogte = $(".side").outerHeight();
          padding = 50;

          //console.log( " intro: " + introteksthoogte + " sb: " + sidebarhoogte + " sb-inner: " + initheight + " p: " + padding);

          //margin-top: 50
          var uitvulhoogte;
          var afbeeldingshoogte;
          afbeeldingshoogte = $(".img").height();
          uitvulhoogte = introteksthoogte;

          if (introteksthoogte >= initheight ) {

            uitvulhoogte = introteksthoogte - padding;

            if( uitvulhoogte - padding < initheight + padding){
                uitvulhoogte += afbeeldingshoogte;
            }
            $(".side").outerHeight( uitvulhoogte );

          } else {





            if(afbeeldingshoogte){
              do{
                  uitvulhoogte += afbeeldingshoogte;
                  //console.log(uitvulhoogte);

              }while( uitvulhoogte < initheight);

              //console.log( uitvulhoogte + " / " + initheight + " / " + padding);

              if( uitvulhoogte - padding < initheight + padding){
                uitvulhoogte += afbeeldingshoogte;
              }
            }
            uitvulhoogte -= padding;

            if(afbeeldingshoogte !== null){
              $(".side").outerHeight(uitvulhoogte);
            }

          }
        }else{
          $(".side").height('auto');
        }

    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  $( window ).resize(UTIL.goedzetten);


})(jQuery); // Fully reference jQuery after this point.
